// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#ff005a' }, // Rosa vibrante
    secondary: { main: '#1f1f1f' }, // Preto escuro
    background: { default: '#f5f5f5' }, // Fundo claro
    text: { primary: '#333', secondary: '#888' },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", sans-serif',
    h1: { fontWeight: 700, fontSize: '3.5rem' },
    h2: { fontWeight: 600, fontSize: '2.5rem' },
    h6: { fontWeight: 500 },
  },
});

export default theme;
