// src/components/Header.jsx
import React from 'react';
import { AppBar, Toolbar, Box, Button, IconButton } from '@mui/material';
import { Link } from 'react-scroll';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

const Header = () => (
  <AppBar
    position="fixed"
    sx={{
      backgroundColor: 'white',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Sombra suave
      zIndex: 1201,
      height: 70, // Altura menor
      justifyContent: 'center', // Centralizar conteúdo verticalmente
    }}
  >
    <Toolbar sx={{ justifyContent: 'space-between', px: 3 }}>
      {/* Logo Redimensionada */}
      <Box
        component="img"
        src="/assets/logo.png"
        alt="Logo Iryd"
        sx={{
          width: 100, // Logo menor
          height: 'auto', 
          objectFit: 'contain',
        }}
      />

      {/* Menu com Links Internos */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {['sobre', 'sistemas', 'contato'].map((section) => (
          <Button
            key={section}
            sx={{
              color: '#333',
              mx: 2,
              textTransform: 'uppercase',
              fontWeight: 'bold',
              '&:hover': { color: '#ff005a' }, // Hover personalizado
            }}
          >
            <Link
              to={section}
              smooth={true}
              duration={500}
              offset={-70}
              style={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </Link>
          </Button>
        ))}
      </Box>

      {/* Ícones de Atendimento e Suporte */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          href="https://wa.me/5511999999999"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#333', mx: 1 }}
        >
          <SupportAgentIcon fontSize="medium" />
        </IconButton>
        <IconButton
          href="mailto:suporte@iryd.com.br"
          sx={{ color: '#333', mx: 1 }}
        >
          <HeadsetMicIcon fontSize="medium" />
        </IconButton>
      </Box>
    </Toolbar>
  </AppBar>
);

export default Header;
