// src/pages/Home.jsx
import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  TextField, 
  Button, 
  Paper 
} from '@mui/material'; 
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { keyframes } from '@emotion/react';

// Animação suave
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Componente para cada Card do Sistema
const SystemCard = ({ logo, description }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Paper 
      elevation={3}
      sx={{ 
        p: 3, 
        borderRadius: 4, 
        textAlign: 'center', 
        transition: '0.3s', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        '&:hover': { transform: 'scale(1.05)' },
      }}
    >
      <Box 
        component="img" 
        src={logo} 
        alt="Logo Sistema" 
        sx={{ 
          width: 120, 
          height: 120, 
          mb: 2, 
          objectFit: 'contain',
        }} 
      />
      <Typography 
        variant="body1" 
        color="text.secondary" 
        sx={{ mt: 1 }}
      >
        {description}
      </Typography>
    </Paper>
  </Grid>
);

const Home = () => (
  <>
    <Header />

    {/* Hero Section */}
    <Box
      id="sobre"
      sx={{
        height: '100vh',
        backgroundImage: 'url(/assets/header.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        px: 2,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 1,
        },
      }}
    >
      <Container sx={{ zIndex: 2 }}>
        <Typography 
          variant="h2" 
          gutterBottom 
          sx={{ 
            animation: `${fadeIn} 1s ease-in-out`,
            fontWeight: 'bold',
          }}
        >
          Transformando Ideias em Realidade Digital
        </Typography>
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 4, 
            animation: `${fadeIn} 1.5s ease-in-out` 
          }}
        >
          Inovação, tecnologia e excelência para impulsionar o seu negócio.
        </Typography>
        <Button 
          variant="contained" 
          color="secondary" 
          size="large" 
          sx={{ 
            animation: `${fadeIn} 2s ease-in-out` 
          }}
        >
          Saiba Mais
        </Button>
      </Container>
    </Box>

    {/* Nossos Sistemas */}
    <Box id="sistemas" sx={{ py: 8, backgroundColor: '#f5f5f5' }}>
      <Container>
        <Typography variant="h2" align="center" gutterBottom>
          Nossos Sistemas
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <SystemCard
            logo="/assets/atalk.webp"
            description="Plataforma de comunicação rápida e eficiente."
          />
          <SystemCard
            logo="/assets/conttalk.png"
            description="Sistema de controle de processos e rotinas, além de super CRM integrado."
          />
          <SystemCard
            logo="/assets/irpratico.png"
            description="Solução prática para controlar os imposto de renda do seus clientes."
          />
          <SystemCard
            logo="/assets/zapfly.png"
            description="Sistema rápido e eficiente para gestão de campanhas."
          />
          <SystemCard
            logo="/assets/xml.png"
            description="Conversão rápida e precisa de arquivos XML."
          />
          <SystemCard
            logo="/assets/nf.png"
            description="Emissão fácil e rápida de notas fiscais eletrônicas."
          />
        </Grid>
      </Container>
    </Box>

    {/* Seção de Contato com Formulário */}
    <Box
      id="contato"
      sx={{
        py: 10,
        background: 'linear-gradient(135deg, #f0f0f0, #d9d9d9)',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="sm">
        <Box 
          sx={{ 
            p: 4, 
            borderRadius: 4, 
            backgroundColor: '#fff', 
            boxShadow: 3 
          }}
        >
          <Typography variant="h2" gutterBottom>
            Entre em Contato
          </Typography>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nome"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Telefone"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="E-mail"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button 
                  variant="contained" 
                  sx={{ 
                    backgroundColor: '#ff4d4d', 
                    '&:hover': { backgroundColor: '#ff6666' }, 
                    color: 'white', 
                    borderRadius: 3 
                  }}
                  fullWidth 
                  type="submit"
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>

    <Footer />

    {/* Botão Flutuante de WhatsApp */}
    <Box
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 1000,
      }}
    >
      <a
        href="https://wa.me/5511999999999"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Button
          variant="contained"
          color="success"
          sx={{ borderRadius: '50%', minWidth: 56, height: 56 }}
        >
          <WhatsAppIcon sx={{ fontSize: 30, color: 'white' }} />
        </Button>
      </a>
    </Box>
  </>
);

export default Home;
