// src/components/Footer.jsx
import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';

const Footer = () => (
  <Box
    sx={{
      backgroundColor: '#1f1f1f',
      py: 5,
      px: 2,
      color: 'white',
      textAlign: 'center',
    }}
  >
    <Grid container spacing={4} justifyContent="center">
      {/* Endereço */}
      <Grid item xs={12} md={4}>
        <Typography variant="h6" gutterBottom>
          Endereço
        </Typography>
        <Typography variant="body2">
          Rua Belem, 1338 <br />
          Campo Real II, Campo Verde - MT <br />
          CEP: 78840-000
        </Typography>
      </Grid>

      {/* Contato */}
      <Grid item xs={12} md={4}>
        <Typography variant="h6" gutterBottom>
          Contato
        </Typography>
        <Typography variant="body2">
          <strong>Telefone:</strong> (66) 3419-1722 <br />
          <strong>E-mail:</strong>{' '}
          <Link href="mailto:contato@iryd.com.br" color="inherit">
            contato@iryd.com.br
          </Link>
        </Typography>
      </Grid>

      {/* Mapa - Google Maps */}
      <Grid item xs={12} md={4}>
        <Typography variant="h6" gutterBottom>
          Localização
        </Typography>
        <Box
          sx={{
            border: '2px solid white',
            borderRadius: '8px',
            overflow: 'hidden',
            height: '200px',
            width: '100%',
          }}
        >
          <iframe
            title="Mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.4902840423345!2d-46.65657448502214!3d-23.58820348467114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59c2fd0b2d8b%3A0x3996c07f9a1f2e99!2sAv.%20Paulista%2C%201575%20-%20Bela%20Vista%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001311-200!5e0!3m2!1spt-BR!2sbr!4v1698505623529!5m2!1spt-BR!2sbr"
            width="100%"
            height="200"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </Box>
      </Grid>
    </Grid>

    {/* Copyright */}
    <Typography variant="body2" sx={{ mt: 5 }}>
      © 2024 Iryd. Todos os Direitos Reservados.
    </Typography>
  </Box>
);

export default Footer;
